import React, {useState} from 'react';
// @mui
import {Button, Menu, MenuItem, Typography} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";

// ----------------------------------------------------------------------

export default function SortIngredientCardList(
    props: {value: {property: string; order: string; label: string;},
            setValue: React.Dispatch<React.SetStateAction<{property: string; order: string; label: string;}>>,
            sortOptions: {property: string; order: string; label: string }[]}
) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event: any) => { // Tipo do Event pode ser mais especifico
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleSelect = (option: { property: string; order: string; label: string; }) => {
        props.setValue(option);
        setOpen(null);
    }

    return (
        <>
            <Button
                size={"large"}
                color="inherit"
                //disabled
                //disableRipple
                onClick={handleOpen}
                endIcon={open ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>}
            >
                Sort By:&nbsp;
                <Typography component="span" variant="subtitle2" sx={{color: 'text.secondary'}}>
                    {props.value.label}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                {props.sortOptions.map((option) => (
                    <MenuItem
                        key={option.property}
                        selected={option.property === 'none'}
                        onClick={() => handleSelect(option)}
                        sx={{typography: 'body2'}}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
