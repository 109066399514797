import msalInstance from "../msalInstance";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

export const fetchAccessToken = async (msalRequest?: any) => {
    try {
        return await msalInstance.acquireTokenSilent({
            ...msalRequest,
            account: msalInstance.getActiveAccount(),
        });
    } catch (error) {
        // call acquireTokenPopup in case of acquireTokenSilent failure
        // due to interaction required
        if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect({
                ...msalRequest,
                account: msalInstance.getActiveAccount()     
            }).catch(e => {
                console.log(e);
            });
        }
    }
}