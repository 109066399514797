// @mui
import {Extension, Home, LunchDining} from "@mui/icons-material";

// ----------------------------------------------------------------------
export const navConfig = [
	{
		title: 'Overview',
		path: '/overview',
		icon: <Home/>,
	},
	{
		title: 'Food Products',
		path: '/food-products',
		icon: <LunchDining/>,
	},
	{
		title: 'Ingredients',
		path: '/ingredients',
		icon: <Extension/>,
	},
];

export const breadcrumbsConfig: any = {
	'/overview': 'Overview',
	'/food-products': 'Food Products',
	'/ingredients': 'Ingredients',
	'/reformulations': 'Reformulations',
	'/food-products/new-food-product': 'New Food Product',
}
