import {Helmet} from 'react-helmet-async';
// hooks
import {useState} from "react";
// @mui
import {Box, Button, Grid, InputAdornment, Stack} from '@mui/material';
import {Add, FileUploadOutlined, Search} from "@mui/icons-material";
import Pagination from '@mui/material/Pagination';
// sections and components
import {SortFoodCardList} from "../sections/@dashboard/foods";
import {StyledSearch} from "../components/search";
import {FoodCard} from "../components/food-card";
// router
import {NavLink as RouterLink} from "react-router-dom";
// utils
import {useQuery} from '@tanstack/react-query';
import {apiRoutes} from "../config";
import {SortOption, SortOptionCreateParams} from "../utils/filteringAndSorting";
import openAPIGeneratorFoodDesignerInstance from "../openAPIGeneratorFoodDesignerInstance";

// ----------------------------------------------------------------------

const sortInitialState: SortOption = {
    property: 'none',
    order: 'asc',
    label: 'None'
}

export const foodPageQuery = (searchQuery: any, sort: SortOption, page: number, pageSize: number) => ({
    queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, searchQuery, sort, page],
    queryFn: () => {
        return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsList(SortOptionCreateParams(sort), page, pageSize, searchQuery)
            .then(response => response.data)
    },
    initialData: {
        "count": 0,
        "next": null,
        "previous": null,
        "results": []
    }
})


export const foodPageLoader = async (queryClient: any, params?: any) => {
    const query = foodPageQuery('', sortInitialState, 1, 9)
    return queryClient.ensureQueryData(query)
}

export default function FoodsPage() {

    const [page, setPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('');

    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date_created', order: "desc", label: 'Newest'},
        {property: 'date_created', order: "asc", label: 'Oldest'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
    ];

    const [sort, setSort] = useState(sortInitialState);

    const pageSize = 9

    const {data: foodProductsData} = useQuery(
        foodPageQuery(searchQuery, sort, page, pageSize)
    )

    const handleFilterByName = (event: any) => {
        setPage(1);
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (event: any, page: number) => {
        setPage(page);
    }

    return (
        <>
            <Helmet>
                <title> Food Products | CibusAI </title>
            </Helmet>

            <Box>
                <Grid container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}>
                    <Grid item xs={12} md={6}>
                        <StyledSearch
                            fontSize={16}
                            width={240}
                            value={searchQuery}
                            onChange={handleFilterByName}
                            placeholder="Search food product..."
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            }
                        />
                        <SortFoodCardList value={sort} setValue={setSort} sortOptions={sortOptions}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction="row"
                               spacing={2}>
                            <Button variant="outlined"
                                    size={"large"}
                                    color={"success"}
                                    disabled
                                    startIcon={<FileUploadOutlined/>}>
                                Bulk Import
                            </Button>
                            <Button variant="outlined"
                                    size={"large"}
                                    startIcon={<Add/>}
                                    component={RouterLink}
                                    to={"new-food-product"}>
                                New Food Product
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container
                              alignItems={"center"}
                              justifyContent={"center"}
                              spacing={3}>
                            {foodProductsData.results.map((food, index) => {
                                return (
                                    <Grid key={index} item xs={12} md={6} lg={4}>
                                        <FoodCard food={food}/>
                                    </Grid>
                                );
                            })}

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2} alignItems={"center"}>
                            <Pagination
                                variant="outlined"
                                shape="rounded"
                                count={Math.ceil(foodProductsData.count / pageSize)}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
