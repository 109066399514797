import * as api from "../src/api/index"
import axiosBackendInstance from "../src/axiosBackendInstance";
import getAccessTokenString from "./utils/getAccessTokenString";

// ----------------------------------------------------------------------

const openAPIGeneratorFoodDesignerInstance =
    new api.FoodDesignerApi(
        new api.Configuration(
            {
                accessToken: getAccessTokenString,
                //basePath: apiRoutes.foodDesigner.baseEndpoint
            }
        ),
        /*apiRoutes.foodDesigner.baseEndpoint*/undefined,
        axiosBackendInstance
    )

export default openAPIGeneratorFoodDesignerInstance;