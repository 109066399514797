import {filter} from "lodash";

// ----------------------------------------------------------------------
function descendingComparator(a: any, b: any, orderBy: any) {
    if(orderBy.includes('.')) {
        const orderBySplit = orderBy.split('.');
        const orderBy1 = orderBySplit[0];
        const orderBy2 = orderBySplit[1];

        if (b[orderBy1][orderBy2] < a[orderBy1][orderBy2]) {
            return -1;
        }
        if (b[orderBy1][orderBy2] > a[orderBy1][orderBy2]) {
            return 1;
        }
        return 0;
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

export function getComparator(order: any, orderBy: any) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array: any, comparator: any, query: any) {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_element) => _element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el: any) => el[0]);
}

// ----------------------------------------------------------------------

export interface SortOption {
    property: string;
    order: string;
    label: string;
}

export function SortOptionCreateParams(order: SortOption){

    if (order.label === "None"){
        return "";
    }
    else {
        if (order.order === "asc") {
            return order.property
        } else if (order.order === "desc") {
            return "-" + order.property
        }
    }
}
