import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";

export default function NewFoodDescription(props: {state: any, handleChanges: any}) {

    return (
        <Grid container spacing={4} sx={{pr: 4}}>
            <Grid item xs={6}>
                <Typography variant={"body1"} paragraph>Name</Typography>
                <TextField variant={"outlined"}
                           label={'Name'}
                           onChange={props.handleChanges('name')}
                           defaultValue={props.state.name}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"body1"} paragraph>Description</Typography>
                <TextField variant={"outlined"}
                           fullWidth
                           multiline
                           label={'Description'}
                           minRows={3}
                           onChange={props.handleChanges('description')}
                           defaultValue={props.state.description}
                />
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} paragraph>Calories</Typography>
                <TextField variant={"outlined"}
                           size={"small"}
                           label={'Calories'}
                           onChange={props.handleChanges('calories')}
                           defaultValue={props.state.calories}
                />
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} paragraph>Serving Size</Typography>
                <TextField variant={"outlined"}
                           size={"small"}
                           label={'Serving Size'}
                           onChange={props.handleChanges('serving')}
                           defaultValue={props.state.serving}
                />
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} paragraph>Serving Size Unit</Typography>
                <FormControl>
                    <InputLabel id="select-unit">Unit</InputLabel>
                    <Select variant={"outlined"}
                            size={"small"}
                            label={'Unit'}
                            sx={{minWidth: 120}}
                            onChange={props.handleChanges('serving_unit')}
                            defaultValue={props.state.serving_unit}
                    >
                        <MenuItem value={'g'}> g </MenuItem>
                        <MenuItem value={'ml'}> ml </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} paragraph> Nutritional score </Typography>
                <FormControl>
                    <InputLabel id="select-nutritional">Nutritional Score</InputLabel>
                    <Select variant={"outlined"}
                            size={"small"}
                            label={'Nutritional Score'}
                            sx={{minWidth: 120}}
                            onChange={props.handleChanges('nutritional_score')}
                            defaultValue={props.state.nutritional_score}
                    >
                        <MenuItem value={'A'}> A </MenuItem>
                        <MenuItem value={'B'}> B </MenuItem>
                        <MenuItem value={'C'}> C </MenuItem>
                        <MenuItem value={'D'}> D </MenuItem>
                        <MenuItem value={'E'}> E </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} paragraph> Cost </Typography>
                <TextField variant={"outlined"}
                           size={"small"}
                           label={'Cost'}
                           onChange={props.handleChanges('price')}
                           defaultValue={props.state.price}
                />
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} paragraph> Price Currency </Typography>
                <FormControl>
                    <InputLabel id="select-currency">Currency</InputLabel>
                    <Select variant={"outlined"}
                            size={"small"}
                            label={'Currency'}
                            sx={{minWidth: 120}}
                            onChange={props.handleChanges('price_unit')}
                            defaultValue={props.state.price_unit}
                    >
                        <MenuItem value={'€'}>€</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}