import {Chip, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip} from "@mui/material";
import {Article, AutoGraph, Science} from "@mui/icons-material";
import {annotationTypes, annotationTypeSets, predictionThresholds} from "../../config";
import {groupBy} from "../../utils/grouping";

export default function IngredientHighlights(props: {
    ingredient: any,
    size?: "medium" | "small",
    align?: "inherit" | "center" | "left" | "right" | "justify"
}) {

    const insights = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: {
        type: string;
    }) => annotationTypeSets.insights.has(annotation.type)) : [];
    const insightsGroupedByType = groupBy(insights, "type")
    const rows = Object.entries<any>(insightsGroupedByType).map(([label, annotations]) => {
        return {
            title: annotationTypes[label],
            insightsValues: annotations.map((insight: { id: any; label: any; value: any; }) => ({
                key: insight.id,
                label: insight.label,
                value: insight.value
            }))
        }
    })

    return (
        <TableContainer sx={{pt: 2}}>
            <Table aria-label="simple table" size={props.size}>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.title}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" align={props.align} scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell component="th" align={props.align} scope="row">
                                <Stack direction={"row"} spacing={1}>
                                    {row.insightsValues.map((insight: any) => {
                                        if (insight.value === "Regulatory Information") {
                                            return (
                                                <Tooltip title={insight.value} followCursor>
                                                    <Chip label={insight.label}
                                                          color={"success"}
                                                          size={props.size}
                                                          variant={"outlined"}
                                                          icon={<Article/>}
                                                          component="a"
                                                          href="#"
                                                    />
                                                </Tooltip>
                                            )
                                        } else if (insight.value === 'Experimental Evidence') {
                                            return (
                                                <Tooltip title={insight.value} followCursor>
                                                    <Chip label={insight.label}
                                                          color={"success"}
                                                          size={props.size}
                                                          variant={"outlined"}
                                                          icon={<Science/>}
                                                          component="a"
                                                          href="#"
                                                    />
                                                </Tooltip>
                                            )
                                        } else if (parseFloat(insight.value) > predictionThresholds.insights) {
                                            return (
                                                <Tooltip title={`Predicted ${parseFloat(insight.value) * 100}`}
                                                         followCursor>
                                                    <Chip label={insight.label}
                                                          color={"primary"}
                                                          size={props.size}
                                                          variant={"outlined"}
                                                          icon={<AutoGraph/>}
                                                          component="a"
                                                          href="#"
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    })}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
