import {useMemo} from "react";
// @mui
import {Chip, Grid, IconButton, Paper, Stack, Tooltip, Typography} from "@mui/material";
// components
import {Article, AutoGraph, ExtensionOutlined, Science} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import {annotationTypes, annotationTypeSets, apiRoutes} from "../../../config";
import {groupBy} from "../../../utils/grouping";
import {useQuery} from "@tanstack/react-query";
import {Link as RouterLink} from "react-router-dom";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";

// ----------------------------------------------------------------------


function ChipScore(props: { value: number }) {
    const rounded_value = Math.round((props.value + Number.EPSILON) * 100 * 100) / 100

    if (rounded_value > 75) {
        return (
            <Tooltip title={`High Score`} followCursor>
                <Chip label={"high"}
                      color={"success"}
                      variant={"outlined"}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (rounded_value > 50) {
        return (
            <Tooltip title={`Medium score`} followCursor>
                <Chip label={"medium"}
                      color={"warning"}
                      variant={"outlined"}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else {
        return (
            <Tooltip title={`Low Score`} followCursor>
                <Chip label={"low"}
                      color={"info"}
                      variant={"outlined"}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    }
}


function ChipEvidenceOrPrediction(props: { insight: { key?: any; value?: any; label?: any; }}) {
    if (props.insight.value === "Regulatory Information") {
        return (
            <Tooltip title={props.insight.value} followCursor>
                <Chip label={props.insight.label}
                      color={"success"}
                      variant={"outlined"}
                      icon={<Article/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (props.insight.value === 'Experimental Evidence') {
        return (
            <Tooltip title={props.insight.value} followCursor>
                <Chip label={props.insight.label}
                      color={"success"}
                      variant={"outlined"}
                      icon={<Science/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (props.insight.value > 75) {
        return (
            <Tooltip title={`Predicted ${props.insight.value}`} followCursor>
                <Chip label={props.insight.label}
                      color={"primary"}
                      variant={"outlined"}
                      icon={<AutoGraph/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else {
        return null
    }
}

export const ingredientAlternativesQuery = (ingredientId: any) => ({
        queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredientId, "alternatives"],
        queryFn: () => openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesAlternativesRetrieve(ingredientId)
            .then(
                response => response.data
            ),
    }
)

function ChipStackEvidencePrediction(props: {insightsValues: any}) {

    return (
        <Stack direction={'row'} spacing={1}>
            {props.insightsValues.length > 2 ?
                <>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[0]} key={props.insightsValues[0].key}/>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[1]} key={props.insightsValues[1].key}/>
                    <Chip label={"+" + (props.insightsValues.length - 2)}
                          size={"small"}
                          key={"more"}
                    />
                </>
                :
                props.insightsValues.map((insight: { key?: any; value?: any; label?: any; }) => <ChipEvidenceOrPrediction insight={insight} key={insight.key}/>)
            }
        </Stack>
    )
}

function getInsightsColumns(ingredient: { annotations: any[]; }) {
    const insightsColumns: any = {}

    const insights = ingredient.annotations ? ingredient.annotations.filter((annotation) => annotationTypeSets.insights.has(annotation.type)) : [];
    const insightsGroupedByType = groupBy(insights, "type")
    Object.entries<any>(insightsGroupedByType).forEach(([label, annotations]) => {
        insightsColumns[annotationTypes[label]] = annotations.map((insight: { id: any; label: any; value: any; }) => ({
            key: insight.id,
            label: insight.label,
            value: insight.value
        }))
    })

    return insightsColumns;
}

export default function IngredientAlternatives(props: {ingredient: any}) {
    const insights = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: { type: string; }) => annotationTypeSets.insights.has(annotation.type)).map((element: { label: any; }) => element.label) : [];

    const columns = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: (props: {renderedCellValue: any}) => <Typography variant={"subtitle2"}>{props.renderedCellValue}</Typography>,
                },
                {
                    accessorKey: 'score',
                    header: 'Score',
                    Cell: (props: {renderedCellValue: any}) => <ChipScore value={props.renderedCellValue}/>,
                },
                ...[...annotationTypeSets.insights].map((key) => ({
                    accessorKey: annotationTypes[key],
                    header: annotationTypes[key],
                    columnFilterModeOptions: ['fuzzy'],
                    size: 100,
                    Cell: (props: {renderedCellValue: any}) => props.renderedCellValue ?
                        <ChipStackEvidencePrediction insightsValues={props.renderedCellValue}/> : <></>
                })),
            ]
        },
        [],
    );

    const {isLoading, data: alternativesData} = useQuery<any>(
        ingredientAlternativesQuery(props.ingredient.id)
    )

    const data = useMemo(
        () => {
            return alternativesData ? alternativesData.map((ingredient: any) => ({
                id: ingredient.id,
                name: ingredient.name,
                score: ingredient.similarity_scores.overall_score,
                ...getInsightsColumns(ingredient)
            })) : []
        },
        [alternativesData],
    );


    return (
        <Grid container
              direction={"row"}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}>
            <Grid item xs={12}>
                {
                    insights.length > 0 ?
                        <Paper>

                            <MaterialReactTable columns={columns}
                                                data={data ? data : []}
                                                enableColumnFilterModes
                                                enableRowActions
                                                renderRowActions={({row}) => (
                                                    <Tooltip title={"See Ingredient"}>
                                                        <IconButton component={RouterLink}
                                                                    to={`/ingredients/${row.original.id}`}>
                                                            <ExtensionOutlined
                                                                color={"success"}></ExtensionOutlined>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                            />
                        </Paper>
                        :
                        <Typography>
                            No suggestions available
                        </Typography>
                }
            </Grid>
        </Grid>
    )
}

