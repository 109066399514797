import React, {useState} from 'react';
// @mui
import {Box, Button, ListItemIcon, Menu, MenuItem} from '@mui/material';
import {ArrowDropDown, ArrowDropUp, CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {ChipStack} from "../../../components/chip-stacks";


export default function FilterIngredientCardList(props: { value: any, setValue: any }) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event: any) => { // Tipo do Event pode ser mais especifico
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleSelect = (option: { key: string; value: boolean; name: string; }) => {
        const newValue = props.value.map((item: { key: string; value: boolean; name: string; }) => {
            if (item.key === option.key) {
                return {
                    ...item,
                    value: !item.value,
                };
            }
            return item;
        });
        props.setValue(newValue);
        setOpen(null);
    }

    const activeFilters = props.value.filter((option: { key: string; value: boolean; name: string; }) => option.value).map((option: { key: string; value: boolean; name: string; }) => option.name);

    return (
        <Box>
            <Button
                size={"large"}
                color="inherit"
                disableRipple
                disabled
                onClick={handleOpen}
                endIcon={open ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
            >
                Filter By:
            </Button>
            <ChipStack stringArray={activeFilters} />
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {props.value.map((option: { key: string; value: boolean; name: string; }) => (
                    <MenuItem
                        key={option.key}
                        selected={false}
                        onClick={() => handleSelect(option)}
                        sx={{ typography: 'body2' }}
                    >
                        <ListItemIcon>
                            {option.value ? <CheckBox /*size="small"*/ /> : <CheckBoxOutlineBlank /*size="small"*/ />}
                        </ListItemIcon>
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
