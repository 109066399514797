import React, {useState} from 'react';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SmartToy from '@mui/icons-material/SmartToy';
import {ChatUI} from "../../../components/chatbot";
import throttle from 'lodash.throttle';

//------------------------------------------------------------------------------------------------

//TODO: Chatbot window can be adjusted!

export const openedMixin = (theme: Theme, width: number): CSSObject => ({
    background: theme.palette.background.default,
    width: width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    background: theme.palette.background.default,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

interface DrawerProps {
    open: boolean;
    width: number;
    theme?: Theme;
}

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})<DrawerProps>(
    ({theme, open, width}) => ({
        width: width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        height: '100%',  // Ensure the drawer takes full height
        overflow: 'hidden',  // Prevent scrolling inside the drawer
        ...(open && {
            ...openedMixin(theme, width),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme, width),
                overflow: 'hidden',  // Prevent scrolling inside the drawer
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
                ...closedMixin(theme),
                overflow: 'hidden',  // Prevent scrolling inside the drawer
            },
        }),
    })
)

export default function ChatBotDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState<boolean>(false);
    const [drawerWidth, setDrawerWidth] = React.useState<number>(450);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleResize = React.useCallback(
        throttle((event: MouseEvent) => {
            const newWidth = window.innerWidth - event.clientX;
            setDrawerWidth(Math.max(400, Math.min(newWidth, 600)));
        }, 100),
        []
    );

    return (
        <Drawer
            variant="permanent"
            open={open}
            anchor="right"
            width={drawerWidth}
        >
            <Toolbar/>
            <Stack direction="row" sx={{
                justifyContent: "end"
            }}>
                {open && (
                    <>
                        <div
                            style={{width: '5px', cursor: 'col-resize'}}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                window.addEventListener('mousemove', handleResize);
                                window.addEventListener('mouseup', () => {
                                    window.removeEventListener('mousemove', handleResize);
                                }, {once: true});
                            }}
                        />
                        <ChatUI handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
                        <Divider orientation="vertical"/>
                    </>
                )}
                <List disablePadding>
                    <ListItem key={"Expand"} disablePadding sx={{display: 'block'}}>
                        <ListItemButton
                            onClick={open ? handleDrawerClose : handleDrawerOpen}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'center' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 0 : 0,
                                    justifyContent: 'center',
                                }}
                            >
                                <SmartToy/>
                            </ListItemIcon>
                            <ListItemText primary={""} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Stack>
        </Drawer>
    );
};
