import {Avatar} from "@mui/material";

export default function ScoreAvatar(props: {score: "A" | "B" | "C" | "D" | "E" | string, size?: number}) {

    const { score, size = 100} = props;
    let avatarColor;

    if (score === 'A') {
        avatarColor = '#4caf50';
    } else if (score === 'B') {
        avatarColor = '#8bc34a';
    } else if (score === 'C') {
        avatarColor = '#ffeb3b';
    } else if (score === 'D') {
        avatarColor = '#ff9800';
    } else if (score === 'E') {
        avatarColor = '#f44336';
    } else {
        avatarColor = '#9e9e9e';
    }

    return (
        <Avatar sx={{
            width: size,
            height: size,
            fontSize: 35,
            color: 'white',
            backgroundColor: avatarColor
        }}>
            {score}
        </Avatar>
    );
}
