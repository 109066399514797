import React from "react";
import {useMsal} from "@azure/msal-react";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";

export default function LogoutButton({...props}) {
    const {instance} = useMsal();

    const handleLogout = async () => {

        // logout using msal
        return await instance.logoutRedirect()
    }

    return (
        <Button
            {...props}
            type="submit"
            variant="contained"
            onClick={handleLogout}
            component={RouterLink} to={"/"}>
            <Typography>
                Logout
            </Typography>
        </Button>
    )
}