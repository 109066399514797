import {useMemo} from "react";
// @mui
import {Box, Divider, Grid, Paper, Typography} from "@mui/material";
// material react table
import {MaterialReactTable} from "material-react-table";
// components
import {FoodInfoCard} from "../../../components/food-info-card";
// mock
import {ChipStack} from "../../../components/chip-stacks";
import {IngredientButtons} from "../../../components/ingredient-buttons";
import {annotationTypes, annotationTypeSets} from "../../../config";
import {groupBy} from "../../../utils/grouping";

// ----------------------------------------------------------------------

export function getLogisticsColumns(ingredient: any) {
    const insightsColumns: any = {}

    const insights = ingredient.annotations ? ingredient.annotations.filter((annotation: any) => annotationTypeSets.logistics.has(annotation.type)) : [];
    const insightsGroupedByType = groupBy(insights, "type")
    Object.entries<any>(insightsGroupedByType).forEach(([label, annotations]) => {
        insightsColumns[annotationTypes[label]] = annotations.map((insight: any) => ({
            key: insight.id,
            label: insight.label,
            value: insight.value
        }))
    })

    return insightsColumns;
}

export default function FoodDetails(props: {food: any}) {

    const columns = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: ({renderedCellValue}: any) => <Typography variant={"subtitle2"}>{renderedCellValue}</Typography>,
                },
                ...[...annotationTypeSets.logistics].map((key) => ({
                    accessorKey: annotationTypes[key],
                    header: annotationTypes[key],
                    columnFilterModeOptions: ['fuzzy'],
                    size: 100,
                    Cell: ({renderedCellValue}: any) => renderedCellValue ?
                        <ChipStack stringArray={renderedCellValue.map((obj: any) => obj.label)}/> : <></>
                }))
            ]
        },
        [],
    );

    const data = useMemo(
        () => props.food.ingredients.map((ingredient: any) => ({
            id: ingredient.id,
            name: ingredient.name,
            type: ingredient.type,
            ...getLogisticsColumns(ingredient)
        })),
        [props.food.ingredients],
    );

    return (
        <Grid container
              columnSpacing={2}
              rowSpacing={4}
              justifyContent="flex-start"
              alignItems="flex-start">
            <Grid item xs={12}>
                <FoodInfoCard food={props.food}/>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <Divider sx={{mb: 2}}/>
                    <Typography variant={"h5"} color={"primary"} paragraph>
                        Properties & Labels
                    </Typography>
                    <Paper>
                        <MaterialReactTable columns={columns}
                                            data={data}
                                            enableColumnFilterModes
                                            enableRowActions
                                            renderRowActions={({row}) => (
                                                <IngredientButtons foodName={props.food.name}
                                                                   ingredientType={row.original.type}
                                                                   ingredientUID={row.original.id}
                                                                   ingredientName={row.original.name}/>

                                            )}
                        />
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    )
}