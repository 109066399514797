import {Chip, Stack} from "@mui/material";


export default function ChipStack(props: {stringArray: any}) {
    return (
        <Stack direction={'row'} spacing={1}>
            {props.stringArray.length > 2 ?
                <>
                    <Chip key={1} label={props.stringArray[0]} size={"small"}/>
                    <Chip key={2} label={props.stringArray[1]} size={"small"}/>
                    <Chip key={3} label={"+" + (props.stringArray.length - 2)} size={"small"}/>
                </>
                :
                props.stringArray.map((element: any, index: any) => <Chip key={index} label={element} size={"small"}/>)
            }
        </Stack>
    )
}
