import * as api from "../src/api/index"
import getAccessTokenString from "./utils/getAccessTokenString";
import axiosChatBotInstance from "./axiosChatBotInstance";

// ----------------------------------------------------------------------

const openAPIGeneratorChatInstance =
    new api.AssistantApi(
        new api.Configuration(
            {
                accessToken: getAccessTokenString
            }
        ),
        undefined,
        axiosChatBotInstance
    )

export default openAPIGeneratorChatInstance;