import SmilesDrawer from "smiles-drawer";

export default function SmilesDrawerFunc(SETTINGS, svgElement, canonical_smiles){

    const drawer = new SmilesDrawer.SvgDrawer(SETTINGS);

    SmilesDrawer.parse(canonical_smiles, function (tree) {
        drawer.draw(tree, svgElement.current, "light");
        }, function (err) {
            console.log(err);
        }
    )
}
