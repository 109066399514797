import {RouterProvider} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
// theme
import ThemeProvider from './theme';
// auth
import {MsalProvider} from "@azure/msal-react";
// Query State
import {QueryClientProvider} from '@tanstack/react-query';
// ----------------------------------------------------------------------

export default function App(props: {pca: any, queryClient: any, router: any}) {

    return (
        <MsalProvider instance={props.pca}>
            <HelmetProvider>
                <QueryClientProvider client={props.queryClient}>
                    <ThemeProvider>
                        <RouterProvider router={props.router}/>
                    </ThemeProvider>
                </QueryClientProvider>
            </HelmetProvider>
        </MsalProvider>
    );
}
