import {createBrowserRouter, Navigate} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// templates
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
// pages
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import OverviewPage, {overviewPageLoader} from './pages/OverviewPage';
import FoodsPage, {foodPageLoader} from "./pages/FoodsPage";
import FoodDetailPage, {foodDetailPageLoader} from "./pages/FoodDetailPage";
import IngredientsPage, {ingredientsPageLoader} from "./pages/IngredientsPage";
import IngredientDetailPage, {ingredientDetailLoader} from "./pages/IngredientDetailPage";
import NewFoodPage from "./pages/NewFoodPage";
import {fetchAccessToken} from './utils/auth';

// ----------------------------------------------------------------------

export default function router(pca: any, queryClient: any) {
    return createBrowserRouter([
        {
            path: '',
            element: <>
                <AuthenticatedTemplate>
                    <DashboardLayout/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Navigate to={"/login"}/>
                </UnauthenticatedTemplate>
            </>,
            children: [
                {
                    element: <Navigate to="overview"/>,
                    index: true
                },
                {
                    path: 'overview',
                    element: <OverviewPage/>,
                    loader: async () => await fetchAccessToken() ? overviewPageLoader(queryClient) : null
                },
                {
                    path: 'food-products',
                    element: <FoodsPage/>,
                    id: 'food-products',
                    loader: async () => await fetchAccessToken() ? foodPageLoader(queryClient) : null
                },
                {
                    path: 'food-products/:foodId',
                    element: <FoodDetailPage/>,
                    id: 'food-products-detail',
                    loader: async ({params}) => await fetchAccessToken() ? foodDetailPageLoader(queryClient, params) : null,
                },
                {
                    path: 'food-products/new-food-product',
                    element: <NewFoodPage/>
                },
                {
                    path: 'ingredients',
                    element: <IngredientsPage/>,
                    id: 'ingredients',
                    loader: async () =>  await fetchAccessToken() ? ingredientsPageLoader(queryClient) : null
                },
                {
                    path: 'ingredients/:ingredientId',
                    element: <IngredientDetailPage/>,
                    id: 'ingredients-detail',
                    loader: async ({params}) => await fetchAccessToken() ? ingredientDetailLoader(queryClient, params) : null,
                },
                // {
                //     path: 'reformulations',
                //     element: <ReformulationsPage/>,
                //     id: 'reformulations',
                //     // loader: async () => reformulations,
                // },
                // {
                //     path: 'reformulations/:reformulationId',
                //     element: <ReformulationDetailPage/>,
                //     id: 'reformulations-detail',
                //     // loader: async ({params}) => reformulations.find((reformulation) => reformulation.uid === Number(params.reformulationId)),
                // },
                // {
                //     path: 'reformulations/new-reformulation',
                //     element: <NewReformulationPage/>
                // },
            ],
        },
        {
            path: 'login',
            element: <LoginPage/>,
        },
        {
            element: <SimpleLayout/>,
            children: [
                {element: <Navigate to="overview"/>, index: true},
                {path: '404', element: <Page404/>},
                {path: '*', element: <Navigate to="/404"/>},
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace/>,
        },
    ]);
}
