import {useMemo} from "react";
// @mui/material
import {Box, Chip, Paper, Stack, Tooltip, Typography,} from "@mui/material";
import {Article, AutoGraph, Science} from "@mui/icons-material";
// material react table
import {MaterialReactTable} from "material-react-table";
import {IngredientButtons} from "../../../components/ingredient-buttons";
import {annotationTypes, annotationTypeSets} from "../../../config";
import {groupBy} from "../../../utils/grouping";


function ChipEvidenceOrPrediction(props: {insight: any}) {
    if (props.insight.value ===  "Regulatory Information") {
        return (
            <Tooltip title={props.insight.value} followCursor>
                <Chip label={props.insight.label}
                      color={"success"}
                      variant={"outlined"}
                      icon={<Article/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (props.insight.value === 'Experimental Evidence') {
        return (
            <Tooltip title={props.insight.value} followCursor>
                <Chip label={props.insight.label}
                      color={"success"}
                      variant={"outlined"}
                      icon={<Science/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (props.insight.value > 75) {
        return (
            <Tooltip title={`Predicted ${props.insight.value}`} followCursor>
                <Chip label={props.insight.label}
                      color={"primary"}
                      variant={"outlined"}
                      icon={<AutoGraph/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else {
        return null
    }
}


// ----------------------------------------------------------------------
function ChipStackEvidencePrediction(props: {insightsValues: any}) {

    return (
        <Stack direction={'row'} spacing={1}>
            {props.insightsValues.length > 2 ?
                <>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[0]} key={props.insightsValues[0].key}/>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[1]} key={props.insightsValues[1].key}/>
                    <Chip label={"+" + (props.insightsValues.length - 2)}
                          size={"small"}
                          key={"more"}
                    />
                </>
                :
                props.insightsValues.map((insight: any) => <ChipEvidenceOrPrediction insight={insight} key={insight.key}/>)
            }
        </Stack>
    )
}

function getInsightsColumns(ingredient: any) {
    const insightsColumns: any = {}

    const insights = ingredient.annotations ? ingredient.annotations.filter((annotation: any) => annotationTypeSets.insights.has(annotation.type)) : [];
    const insightsGroupedByType = groupBy(insights, "type")
    Object.entries<any>(insightsGroupedByType).forEach(([label, annotations]): any => {
        insightsColumns[annotationTypes[label]] = annotations.map((insight: any) => ({
            key: insight.id,
            label: insight.label,
            value: insight.value
        }))
    })

    return insightsColumns;
}

// ----------------------------------------------------------------------
export default function FoodInsightsSection(props: {food: any}) {

    const columns: any = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: ({renderedCellValue}: any) => <Typography variant={"subtitle2"}>{renderedCellValue}</Typography>,
                },
                ...[...annotationTypeSets.insights].map((key) => ({
                    accessorKey: annotationTypes[key],
                    header: annotationTypes[key],
                    columnFilterModeOptions: ['fuzzy'],
                    size: 100,
                    Cell: ({renderedCellValue}: any) => renderedCellValue ?
                        <ChipStackEvidencePrediction insightsValues={renderedCellValue}/> : <></>
                })),
            ]
        },
        [],
    );

    const data = useMemo(
        () => {
            return props.food.ingredients.map((ingredient: any) => ({
                id: ingredient.id,
                name: ingredient.name,
                type: ingredient.type,
                ...getInsightsColumns(ingredient)
            }))
        },
        [props.food.ingredients],
    );

    return (
        <Box>
            <Paper>
                <MaterialReactTable columns={columns}
                                    data={data}
                                    enableColumnFilterModes
                                    enableRowActions
                                    renderRowActions={({row}: any) => (
                                        <IngredientButtons foodName={props.food.name}
                                                           ingredientUID={row.original.id}
                                                           ingredientName={row.original.name}
                                                           ingredientType={row.original.type}
                                        />

                                    )}
                />
            </Paper>
        </Box>
    )
}