// axios
import axios from "axios";
// msal and authentication
import {fetchAccessToken} from "./utils/auth";
import {msalChatbotRequest} from "./authConfig";

// ----------------------------------------------------------------------
const axiosChatBotInstance = axios.create({
    baseURL: process.env.REACT_APP_CHATBOT_URL ?? "",
    headers: {'Content-Type': 'application/json'},
    withCredentials: true,
});

axiosChatBotInstance.interceptors.request.use(async (config) => {
        const response = await fetchAccessToken(msalChatbotRequest)
        if (response) {
            config.headers["Authorization"] = "Bearer " + response.accessToken;
        } else {
            console.log("Can't reach auth.")
        }
        return config;
    },
    (error) => {
        Promise.reject(error).then(r => console.log(r));
    }
);

export default axiosChatBotInstance;