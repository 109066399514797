// router
import {Link as RouterLink} from "react-router-dom";

// @mui
import {Card, CardActionArea, CardContent, CardMedia, Divider, Stack, Typography} from "@mui/material";
import {CalendarToday} from "@mui/icons-material";

// utils
import {fDate} from "../../utils/formatTime";
import {imageFallBacks} from "../../config";
import {Product} from "../../api";


export default function FoodCard(props: {food: Product}) {

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea component={RouterLink}
                            to={`/food-products/${props.food.id}`}>
                <CardMedia
                    sx={{ height: 100 }}
                    image={props.food.img ? `${props.food.img}` : imageFallBacks.foodproduct}
                    title={props.food.name}/>
                <CardContent>
                    <Typography sx={{marginRight: 2}}
                          color="inherit"
                          variant="h5"
                          noWrap>
                        {props.food.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.food.description!.length > 70 ? props.food.description?.substring(0, 70) + "..." : props.food.description}
                    </Typography>
                    <Divider sx={{my: 2}} />
                    <Stack direction={'column'} spacing={1}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <CalendarToday />
                            <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                                {fDate(props.food.date_created)}
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}