import {Fragment} from "react";
// router
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {ArrowForward, PlayArrow} from "@mui/icons-material";
// components
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------
export default function LatestIngredients(props: {name: any, description: any, list: any, other?: any[]}) {
    return (
        <Card {...props.other}>
            <CardHeader title={props.name} subheader={props.description}/>

            <Scrollbar>
                <List sx={{p: 3, pr: 0}}>
                    {props.list.map((ingredient: any, index: any) => (
                        <Fragment key={index}>
                            <ListItem>
                                <ListItemButton href={"/ingredients/" + ingredient.id}>
                                    <ListItemIcon>
                                        <PlayArrow color={'success'} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={ingredient.name}
                                    />
                                </ListItemButton>
                            </ListItem>
                            {index < props.list.length - 1 && <Divider />}
                        </Fragment>
                    ))}
                </List>
            </Scrollbar>

            <Divider/>

            <Box sx={{p: 2, textAlign: 'right'}}>
                <Button size="small"
                        color="inherit"
                        endIcon={<ArrowForward/>}
                        component={RouterLink}
                        to="/ingredients">
                    View all
                </Button>
            </Box>
        </Card>
    );
}
