import {Avatar, Card, CardActionArea, CardContent, Stack, Typography} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";

export default function OverviewCard (props: { title: any, value: any, subTitle?: any, subValue?: any, children: any, color: any, sx: any, to?: any }) {

    return (
        <Card sx={props.sx}>
            <CardActionArea  component={RouterLink}
                             to={props.to}>
                <CardContent>
                    <Stack
                        alignItems="flex-start"
                        direction="row"
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Stack spacing={1}>
                            <Typography
                                color="text.secondary"
                                variant="overline"
                            >
                                {props.title}
                            </Typography>
                            <Typography variant="h4">
                                {props.value}
                            </Typography>
                        </Stack>
                        <Avatar
                            sx={{
                                backgroundColor: props.color,
                                height: 56,
                                width: 56
                            }}
                        >
                            {props.children}
                        </Avatar>
                    </Stack>
                    <Typography
                        color={props.color}
                        variant="body2"
                    >
                        {props.subValue} {props.subTitle}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
