import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box, Link} from '@mui/material';

// ----------------------------------------------------------------------

const Logo: any = forwardRef<any, any>(({disabledLink = false, sx}, ref) => {
	// -------------------------------------------------------
	const logo = (
		<Box
			component="img"
			src="/assets/cibus_B.png" alt="CibusAI"
			sx={{height: 25, cursor: 'pointer', ...sx}}
		/>
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return (
		<Link to="/" component={RouterLink} sx={{display: 'contents'}}>
			{logo}
		</Link>
	);
});

Logo.propTypes = {
	sx: PropTypes.object,
	disabledLink: PropTypes.bool,
};

export default Logo;
