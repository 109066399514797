// @mui
import {Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';

//@msal and authentication
import {useMsal} from "@azure/msal-react";
import {msalLoginRequest} from "../../../authConfig";

// ----------------------------------------------------------------------
export default function LoginForm() {
	const {instance} = useMsal();

	const handleLogin = () => {
		instance.loginPopup(
			msalLoginRequest
		).catch(e => {
			console.log(e);
		});
	}

	return (
		<>
			<Stack spacing={3}>
				<LoadingButton size="large"
				               type="submit"
				               variant="outlined"
				               onClick={handleLogin}>
					Login
				</LoadingButton>
			</Stack>
		</>
	);
}
