// react-router
import {Link as RouterLink, useNavigate} from "react-router-dom";
// @mui
import {IconButton, Stack, Tooltip} from "@mui/material";
import {ExtensionOutlined, MenuBook} from "@mui/icons-material";

// ----------------------------------------------------------------------

/*export function SimpleIngredientButtons(props:{ingredientUID: any, ingredientName: any}) {
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
            <Tooltip title={"See Ingredient"}>
                <IconButton component={RouterLink} to={`/ingredients/${props.ingredientUID}`}>
                    <ExtensionOutlined color={"success"}></ExtensionOutlined>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}*/

export default function IngredientButtons(props: {foodName?: any, ingredientUID: any, ingredientName: any, ingredientType?: any}) {
    const navigate = useNavigate();

    const handleReformulate = () => {
        navigate('/reformulations/new-reformulation', {state: {food: props.foodName, ingredient: props.ingredientName}})
    }

    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
            <Tooltip title={"See Ingredient"}>
                <IconButton disabled={props.ingredientType !== "COM"} component={RouterLink}
                            to={`/ingredients/${props.ingredientUID}`}>
                    <ExtensionOutlined color={props.ingredientType === "COM"? "success" : "disabled"}></ExtensionOutlined>
                </IconButton>
            </Tooltip>
            <Tooltip title={"Reformulate Ingredient"}>
                <IconButton disabled onClick={handleReformulate}>
                    {/*<MenuBook color={"primary"}></MenuBook>*/}
                    <MenuBook color={"disabled"}></MenuBook>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}
