import {useState} from 'react';
// @mui
import {alpha} from '@mui/material/styles';
import {Avatar, Box, IconButton, Popover, Typography} from '@mui/material';
// @msal and authentication
import {useMsal} from "@azure/msal-react";
import LogoutButton from '../../../components/logout-button';

// ----------------------------------------------------------------------
export default function AccountPopover() {
	const [open, setOpen] = useState<any>(null);
	const {accounts} = useMsal();
	const account: any = accounts[0];

	const handleOpen = (event: any) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	function stringToColor(string: string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
		};
	}

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				<Avatar {...stringAvatar(account.name)} />
			</IconButton>

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				transformOrigin={{vertical: 'top', horizontal: 'right'}}
				slotProps={{
					paper:{
						sx: {
							p: 0,
							mt: 1.5,
							ml: 0.75,
							width: 180,
							'& .MuiMenuItem-root': {
								typography: 'body2',
								borderRadius: 0.75,
							},
						},
					}}}
			>
				<Box sx={{my: 1.5, px: 2.5}}>
					<Typography variant="subtitle2" sx={{my: 1.5}} noWrap>
						{account.name}
					</Typography>
					<Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
						{account.username}
					</Typography>
					<LogoutButton fullWidth/>
				</Box>
			</Popover>
		</>
	);
}
