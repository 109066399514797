import {Chip, Grid} from "@mui/material";
import {annotationTypeSets} from "../../config";

export default function IngredientInsightsGrid(props: {ingredient: any}) {

    const insights = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: { type: string; }) => annotationTypeSets.insights.has(annotation.type)).map((element: { label: any; }) => element.label) : [];

    return (
        <Grid container
              spacing={1}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              sx={{pl: 0}}>
            {insights.length > 4 ?
                <>
                    <Grid item xs={"auto"}>
                        <Chip label={insights[0]} size={"small"} color={"primary"}/>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Chip label={insights[1]} size={"small"} color={"primary"}/>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Chip label={insights[2]} size={"small"} color={"primary"}/>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Chip label={insights[3]} size={"small"} color={"primary"}/>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Chip label={"+" + (insights.length - 4)} size={"small"} color={"primary"}/>
                    </Grid>
                </>
                : insights.map((element: any) => {
                    return (
                        <Grid item xs={"auto"}>
                            <Chip label={element} size={"small"} color={"primary"}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}
