import {Outlet, useLocation, useParams, useRouteLoaderData} from 'react-router-dom';
// @mui
import {Box, Toolbar} from "@mui/material";
// components
import Header from './header';
import Nav from './nav';
 import ChatBotDrawer from "./Chatbot";

// ----------------------------------------------------------------------

export default function DashboardLayout() {

    const params = useParams();
    const location = useLocation();
    const base = location.pathname.split('/')[1];
    const loaderData = useRouteLoaderData(base + (Object.keys(params).length > 0 ? "-detail" : ""));

    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Nav />
            <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                <Toolbar />
                <Outlet />
            </Box>
            <ChatBotDrawer />
        </Box>
    );
}
