import * as api from "../src/api/index"
import axiosBackendInstance from "../src/axiosBackendInstance";
import getAccessTokenString from "./utils/getAccessTokenString";

// ----------------------------------------------------------------------

const openAPIGeneratorMaterialLibraryInstance =
    new api.MaterialLibraryApi(
        new api.Configuration(
            {
                accessToken: getAccessTokenString
            }
        ),
        undefined,
        axiosBackendInstance
    )

export default openAPIGeneratorMaterialLibraryInstance;
